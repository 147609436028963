import packageInfo from '../../package.json';

export const environment = {
  production: false,
  dragonboat: false,
  name: 'dev',
  apiBaseUrl: 'https://qa1.aws.repsrv.com/api/v1/admin',
  apigeeApiKey: '49e59bf4eb844ad1999a307a9ea470a6',
  apigeeEndpoint: 'https://republic-services-dev.apigee.net/api',
  azureAd: {
    authority: 'https://login.microsoftonline.com/eeb53a15-a2e4-46de-8608-22657ab58979/',
    clientId: '6e1b871b-3fbf-4980-aecd-2644f7936eed',
    groups: [
      {
        accessLevel: 1,
        displayName: 'AzureSG-MyResourceSupportDev',
        id: 'c5c98c9c-0b81-424a-bb4e-2a40266378db',
        permissionName: 'user'
      },
      {
        accessLevel: 2,
        displayName: 'AzureSG-MyResourceSupportDev2',
        id: '2d14d8e6-b6b8-477f-a8c8-c19c193b3e2e',
        permissionName: 'support'
      }
    ],
    tenant: 'eeb53a15-a2e4-46de-8608-22657ab58979'
  },
  newAzureAd: {
    clientId: 'd5688cb7-bf57-4d16-b524-79edccf85ff8',
    authority: 'https://login.microsoftonline.com/eeb53a15-a2e4-46de-8608-22657ab58979'
  },
  features: {},
  VERSION: packageInfo.version
};
