import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioGroup } from '@angular/material/radio';

import { NewPaymentMethodDialogComponent } from '@container/new-payment-method-dialog/new-payment-method-dialog.component';
import { FisPaymentMethod } from '@interface/fis-payment-method';
import { FisService } from '@service/fis/fis.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaymentMethodsComponent),
      multi: true
    }
  ]
})
export class PaymentMethodsComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @ViewChild('paymentMethod', { static: true })
  public paymentMethod: MatRadioGroup;
  public selectedPaymentType: string;

  @Input() protected inline = false;

  @Output() public donePaymentMethod = new EventEmitter<boolean>();
  @Output() public selectedPaymentMethod = new EventEmitter<FisPaymentMethod>();

  public paymentMethods: FisPaymentMethod[];
  public value = null;

  private destroyed$: Subject<void> = new Subject();

  constructor(public dialog: MatDialog, private fisService: FisService) {}

  public ngAfterViewInit(): void {
    // README: When payment methods are fetched in the service we get the latest and set the first
    // as default, or null when paymentMethods is empty
    this.fisService.paymentMethodsSubject$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((paymentMethods) => {
        this.paymentMethods = paymentMethods;
        this.selectedPaymentType = paymentMethods[0]?.isCredit ? 'C' : 'B';

        if (paymentMethods[0]) {
          this.selectedPaymentMethod.emit(paymentMethods[0]);
        }

        this.onChange(
          (paymentMethods && paymentMethods[0] && paymentMethods[0].paymentMethodId) || null
        );
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public addPaymentMethod(): void {
    const dialogRef = this.dialog.open(NewPaymentMethodDialogComponent, {
      width: '80%',
      height: 'auto',
      position: {
        top: '0'
      },
      panelClass: 'payment-method-dialog-panel',
      closeOnNavigation: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((methodAdded: boolean) => {
      if (methodAdded) {
        this.paymentMethods = null;
        this.donePaymentMethod.emit(true);
      }
    });
  }

  public paymentMethodChanged(event: any): void {
    this.onChange(event.value);

    if (this.paymentMethods?.length) {
      this.selectedPaymentMethod.emit(
        this.paymentMethods.find((pm) => pm.paymentMethodId === event.value)
      );
    }
  }

  private onChange(newValue: any): void {
    this.value = newValue;
  }

  private onSetDisabledState(): void {
    /* no-op */
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(): void {
    /* no-op */
  }

  public setDisabledState(): void {
    this.onSetDisabledState();
  }

  public writeValue(obj: any): void {
    this.value = obj;

    this.onChange(this.value);
  }

  public showNoPaymentMessage(): boolean {
    return this.paymentMethods ? this.paymentMethods.length === 0 : true;
  }
}
