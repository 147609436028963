/* eslint:disable max-classes-per-file */
import { CountryCode } from '@interface/location';

export class BankInfo {
  public bankName?: string;
  public routingNumber?: string;
  public accountNumber?: string;
  public accountHolderName?: string;

  constructor(raw: BankInfoRaw) {
    this.bankName = raw.bankName;
    this.routingNumber = raw.routingNumber;
    this.accountNumber = raw.accountNumber;
    this.accountHolderName = raw.accountHolderName;
  }
}

export interface BankInfoRaw {
  bankName?: string;
  routingNumber?: string;
  accountNumber?: string;
  accountHolderName?: string;
}

export class BillingInfo {
  public firstName: string;
  public middleInitial: string;
  public lastName: string;
  public addressLine1: string;
  public addressLine2: string;
  public city: string;
  public stateCode: string;
  public postalCode: string;
  public countryCode: CountryCode;

  constructor(raw: BillingInfoRaw = {}) {
    this.firstName = raw.firstName || '';
    this.middleInitial = raw.middleInitial || '';
    this.lastName = raw.lastName || '';
    this.addressLine1 = raw.addressLine1 || '';
    this.addressLine2 = raw.addressLine2 || '';
    this.city = raw.city || '';
    this.stateCode = raw.stateCode || '';
    this.postalCode = raw.postalCode || '';

    if (raw.countryCode) {
      this.countryCode =
        (CountryCode[raw.countryCode.toUpperCase()] as CountryCode) || CountryCode.US;
    } else {
      this.countryCode = CountryCode.US;
    }
  }
}

export interface BillingInfoRaw {
  firstName?: string;
  middleInitial?: string;
  lastName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateCode?: string;
  postalCode?: string;
  countryCode?: string;
}

export class CreditCardInfo {
  public creditCardNumber: string;
  public creditCardName: string;
  public creditCardMonth: string;
  public creditCardYear: string;
  public creditCardCvv: string;
  public billingInfo: BillingInfo;

  constructor(raw: CreditCardInfoRaw) {
    this.creditCardNumber = raw.creditCardNumber;
    this.creditCardName = raw.creditCardName;
    this.creditCardMonth = raw.creditCardMonth;
    this.creditCardYear = raw.creditCardYear;
    this.creditCardCvv = raw.creditCardCvv;
    this.billingInfo = new BillingInfo(raw.billingInfo);
  }
}

export interface CreditCardInfoRaw {
  creditCardNumber?: string;
  creditCardName?: string;
  creditCardMonth?: string;
  creditCardYear?: string;
  creditCardCvv?: string;
  billingInfo?: BillingInfo;
}

export class PaymentMethodInfo {
  public accountType?: string;
  public accountNickName?: string;
  public bankInfo?: BankInfo;
  public cardInfo?: CreditCardInfo;
  public paymentMethodId?: string | null;
  public selectedPaymentType?: string;

  constructor(raw: PaymentMethodInfoRaw = {}) {
    if (raw.accountNickName) {
      this.accountNickName = raw.accountNickName;
    }

    if (raw.accountType) {
      this.accountType = raw.accountType;
    }

    if (raw.bankInfo) {
      this.bankInfo = new BankInfo(raw.bankInfo);
    }

    if (raw.cardInfo) {
      this.cardInfo = new CreditCardInfo(raw.cardInfo);
    }

    if (raw.paymentMethodId) {
      this.paymentMethodId = raw.paymentMethodId;
    }

    if (raw.selectedPaymentType) {
      this.selectedPaymentType = raw.selectedPaymentType;
    }
  }
}

export interface PaymentMethodInfoRaw {
  paymentMethodId?: string;
  accountNickName?: string;
  accountType?: string;
  bankInfo?: BankInfo;
  cardInfo?: CreditCardInfo;
  selectedPaymentType?: string;
}

export class PaymentMethodRequest {
  public externalId: string;
  public hasAgreedToStore: string;
  public hasAgreedToUse: string;
  public paymentMethodInfo: PaymentMethodInfo;

  constructor(raw: PaymentMethodRequestRaw) {
    this.externalId = raw.externalId;
    this.hasAgreedToStore = raw.hasAgreedToStore;
    this.hasAgreedToUse = raw.hasAgreedToUse;
    this.paymentMethodInfo = new PaymentMethodInfo(raw.paymentMethodInfo);
  }
}

export interface PaymentMethodRequestRaw {
  externalId?: string;
  hasAgreedToStore?: string;
  hasAgreedToUse?: string;
  paymentMethodInfo?: PaymentMethodInfo;
}
